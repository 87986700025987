import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";

import FormInput from "../../components/Form/FormInput";
import { Message } from "primereact/message";

function ManageIntermediateAccount({
  isOpen,
  handleClose,
  handleCreate,
  handleUpdate,
  handleDelete,
  loading,
  intermediate,
  intermediateAccount,
}) {
  const { getAuthorizedScopeListFromIds, getScopesListFromIds } =
    useAuthorizationsContext();

  const [email, setEmail] = useState("");
  const [alias, setAlias] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedScopeList, setSelectedScopeList] = useState([]);

  useEffect(() => {
    if (intermediateAccount) {
      setEmail(intermediateAccount.email);
      setAlias(intermediateAccount.alias);
      setPhone(intermediateAccount.phone);
      setSelectedScopeList(
        getScopesListFromIds(
          intermediateAccount.authorizations
            .filter((a) => a.type === "READ_WRITE")
            .map((a) => a.linked_scope)
        )
      );
    } else {
      setEmail("");
      setAlias("");
      setPhone("");
      setSelectedScopeList([]);
    }
  }, [isOpen]);

  const onValidate = () => {
    const userForm = {
      email,
      alias,
      phone,
      linked_scopes: selectedScopeList.map((ss) => ss.id),
    };
    intermediateAccount ? handleUpdate(userForm) : handleCreate(userForm);
  };

  const formIsValid =
    alias.length > 2 && email.length > 5 && selectedScopeList.length;

  const footerButtons = (
    <div className="manage-footer">
      {formIsValid && !intermediateAccount ? (
        <Message
          severity="success"
          className="text-sm"
          text={`Un mail sera envoyé à l'utilisateur avec un lien pour choisir son mot de passe`}
        />
      ) : null}
      {intermediateAccount && (
        <Button
          key={"delete"}
          label={`Supprimer le compte`}
          onClick={handleDelete}
          severity="danger"
          outlined
        />
      )}
      <Button
        key={"validate"}
        label={`Valider la ${intermediateAccount ? "modification" : "création"} du compte`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${intermediateAccount ? "Modifier" : "Ajouter"} un compte Intermediaire`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Email"} required isValid={email.length > 5}>
            <InputText
              id="email"
              disabled={intermediateAccount}
              placeholder="Saisir une adresse email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>
          <FormInput
            label={"Nom d'affichage"}
            required
            isValid={alias.length > 2}
          >
            <InputText
              id="alias"
              value={alias}
              placeholder="Saisir un nom d'affichage"
              onChange={(e) => setAlias(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Périmètre"}>
            <SelectButton
              multiple
              value={selectedScopeList}
              onChange={(e) => setSelectedScopeList(e.value)}
              options={getAuthorizedScopeListFromIds(
                intermediate?.linked_scopes
              )}
              optionLabel="label"
            />
          </FormInput>

          <FormInput label={"Numéro de téléphone"}>
            <InputText
              id="phone"
              placeholder="Saisir un numéro de téléphone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageIntermediateAccount;
