import React from "react";
import ImageItem from "../../components/Image/ImageItem";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Data from "../../components/Display/Data";
import DataTag from "../../components/Display/DataTag";
import Section from "../../components/Display/Section";
import ImageList from "../../components/ImageList";

export const BeneficiaryDetail = ({
  beneficiary,
  handleBeneficiaryUpdate,
  handleCreateImage,
  handleUpdateImage,
  handleCreateProject,
}) => {
  const informationActions = (handleCreateProject ||
    handleBeneficiaryUpdate) && [
    {
      onClick: handleCreateProject,
      label: "Ajouter un projet",
      icon: "pi-plus",
      disabled: beneficiary?.project,
    },
    {
      onClick: handleBeneficiaryUpdate,
      label: "Modifier les informations",
      icon: "pi-pencil",
    },
  ];

  const imageActions = handleCreateImage && [
    {
      onClick: handleCreateImage,
      label: "Ajouter une image",
      icon: "pi-plus",
    },
  ];

  return (
    <div className="main-content-container h-auto overflow-y-scroll">
      <Section label="Informations" actions={informationActions}>
        <div className="data-section-row">
          <Data
            key="fullName"
            label={"Nom complet"}
            value={beneficiary?.first_name + " " + beneficiary?.name}
            large
          />
          <Data
            key="project"
            label={"Référence projet"}
            value={beneficiary?.project?.reference || ""}
          />
        </div>

        <div className="data-section-row">
          <Data key="email" label={"Email"} value={beneficiary?.email} large />
          <Data key="phone" label={"Téléphone"} value={beneficiary?.phone} />
          <Data
            key="address"
            label={"Adresse"}
            value={
              beneficiary?.address +
              ", " +
              beneficiary?.zip_code +
              " " +
              beneficiary?.city
            }
            large
          />
        </div>

        <div className="data-section-row">
          <Data
            key="sau"
            label={"SAU Totale"}
            value={beneficiary?.total_surface}
            type="hectare"
          />
          <DataTag
            key="qualityApproach"
            label={"Démarche qualité"}
            value={beneficiary?.quality_approach}
            rounded
          />
          <DataTag
            key="productionType"
            label={"Production"}
            value={
              CONSTANTS.AGRICULTURE_TYPES.find(
                (at) => at.value === beneficiary?.is_organic
              )?.label
            }
            rounded
          />
          <Data
            key="production"
            label={"Types de production"}
            value={beneficiary?.production_types
              .map((pt) => pt.label)
              .join(", ")}
            large
          />
        </div>

        <div className="data-section-row">
          <Data
            key="description"
            label={"Notes internes"}
            value={beneficiary?.private_description}
            large
            multiline
          />
        </div>
      </Section>

      <Section label="Photos" actions={imageActions}>
        <ImageList
          handleImageUpdate={handleUpdateImage}
          images={beneficiary?.photos || []}
        />
      </Section>
    </div>
  );
};
