import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getAuthorizations,
  saveAuthorizations,
} from "../utils/authorizationsHelper";

const AuthorizationsContext = createContext(null);

export const AuthorizationsContextProvider = ({ children }) => {
  const [authorizationsContext, setAuthorizationsContext] =
    useState(getAuthorizations());

  useEffect(() => {
    if (authorizationsContext) {
      saveAuthorizations(authorizationsContext);
    }
  }, [authorizationsContext]);

  const initScopes = (scopes) => {
    scopes.forEach((s) => {
      s.isActive = s.is_authorized;
    });
    setAuthorizationsContext(scopes);
  };

  const selectScope = (scope) => {
    let newScopes = [...authorizationsContext];
    newScopes.forEach((s) => {
      s.isActive = scope.id === s.id;
    });
    setAuthorizationsContext(newScopes);
  };

  const selectAllScopes = () => {
    let newScopes = [...authorizationsContext];
    newScopes.forEach((s) => {
      s.isActive = true;
    });
    setAuthorizationsContext(newScopes);
  };

  const getAuthorizedScopeList = () => {
    return authorizationsContext
      ? authorizationsContext.filter((s) => s.is_authorized)
      : [];
  };

  const getAuthorizedScopeListFromIds = (scopeIdList = []) => {
    if (scopeIdList) {
      return authorizationsContext.filter(
        (s) => scopeIdList.includes(s.id) && s.is_authorized
      );
    }
  };

  const getSubappAuthorizationType = (scopeName, subappName) => {
    const scope = authorizationsContext?.find((s) => s.name === scopeName);
    if (!scope) return false;
    return scope?.authorizations?.find((a) => a.subapp === subappName)?.type;
  };

  const getActiveScopesNames = () => {
    return getAuthorizedScopeList()
      .filter((s) => s.isActive)
      .map((activeScope) => activeScope.name);
  };

  const getActiveScope = () => {
    const activeScopeList = getAuthorizedScopeList().filter((s) => s.isActive);
    if (activeScopeList.length !== 1) {
      return null;
    }
    return activeScopeList[0];
  };

  const isOverviewMode = () => {
    return getActiveScope() === null;
  };

  const getScopesListFromIds = (idList) => {
    if (idList) {
      return authorizationsContext.filter((s) => idList.includes(s.id));
    }
  };

  const getScopeFromId = (id) => {
    return authorizationsContext.find((s) => id === s.id);
  };

  const getScopeFromName = (name) => {
    return authorizationsContext.find((s) => name === s.name);
  };

  return (
    <AuthorizationsContext.Provider
      value={{
        authorizationsContext,
        setAuthorizationsContext,
        initScopes,
        selectScope,
        selectAllScopes,
        getAuthorizedScopeList,
        getAuthorizedScopeListFromIds,
        getSubappAuthorizationType,
        getActiveScope,
        getActiveScopesNames,
        isOverviewMode,
        getScopesListFromIds,
        getScopeFromId,
        getScopeFromName,
      }}
    >
      {children}
    </AuthorizationsContext.Provider>
  );
};

export const useAuthorizationsContext = () => {
  return useContext(AuthorizationsContext);
};
