import React, { useState, useEffect, useMemo } from "react";
import { privateFetch, publicFetch } from "../../../utils/apiHelper";
import { BeneficiaryDatatable } from "../../../modules/beneficiary/BeneficiaryDatatable";
import { BeneficiaryDetail } from "../../../modules/beneficiary/BeneficiaryDetail";
import { ProjectDetail } from "../../../modules/project/ProjectDetail";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import ManageDocument from "../../../modules/managers/ManageDocument";
import ManageImage from "../../../modules/managers/ManageImage";
import ManageBeneficiary from "../../../modules/managers/ManageBeneficiary";
import ManageProject from "../../../modules/managers/ManageProject";
import { confirmDialog } from "primereact/confirmdialog";
import { useBeneficiaryContext } from "../../../contexts/BeneficiaryContext";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import slugify from "react-slugify";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { useDebouncedCallback } from "use-debounce";
import "../../Pages.scss";

function BeneficiaryPage({ hidden }) {
  const { navigationContext, handleBeneficiaryView } = useNavigationContext();
  const { beneficiaryContext, handleSelectedBeneficiary } =
    useBeneficiaryContext();
  const { authorizationsContext, getSubappAuthorizationType } =
    useAuthorizationsContext();
  const { accountContext } = useAccountContext();

  const [productionTypeList, setProductionTypeList] = useState([]);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [beneficiary, setBeneficiary] = useState(null);
  const [beneficiaryModuleOpen, setBeneficiaryModuleOpen] = useState(false);
  const [project, setProject] = useState(null);
  const [projectModuleOpen, setProjectModuleOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imageModuleOpen, setImageModuleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datatableLoading, setDatatableLoading] = useState(true);

  useEffect(() => {
    fetchBeneficiaryList();
    fetchProductionTypes();
  }, []);

  useEffect(() => {
    refreshBeneficiaryList();
  }, [beneficiaryContext]);

  useEffect(() => {
    beneficiaryContext?.selectedBeneficiaryId && fetchBeneficiaryDetail();
  }, [beneficiaryContext?.selectedBeneficiaryId]);

  const refreshBeneficiaryList = useDebouncedCallback(() => {
    fetchBeneficiaryList();
  }, 500);

  // Autoselect project
  useEffect(() => {
    if (!beneficiary) {
      setProject(undefined);
    } else {
      setProject(beneficiary.project);
    }
  }, [beneficiary]);

  const isReadWrite = useMemo(() => {
    // If User has READ_WRITE auth on the PROJECT Subapp
    return getSubappAuthorizationType("terrafine", "PROJECT") === "READ_WRITE";
  }, [authorizationsContext]);

  const fetchProductionTypes = async () => {
    await publicFetch("GET", `/production_types/`).then((res) => {
      if (res) setProductionTypeList(res);
    });
  };

  const fetchBeneficiaryList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/beneficiary/?query=${slugify(
        beneficiaryContext.filters.query
      )}`
    ).then((res) => {
      if (res) setBeneficiaryList(res);
      setDatatableLoading(false);
    });
  };

  const fetchBeneficiaryDetail = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/beneficiary/${beneficiaryContext?.selectedBeneficiaryId}/`
    ).then((res) => {
      if (res) {
        setBeneficiary(res);
      }
    });
  };

  const createBeneficiary = async (beneficiaryForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/`,
      "Une erreur est survenue lors de la création du bénéficiaire",
      "Le bénéficiaire a bien été créé.",
      JSON.stringify(beneficiaryForm)
    ).then((response) => {
      if (response) {
        setBeneficiaryModuleOpen(false);
        setLoading(false);
        fetchBeneficiaryList();
        handleSelectedBeneficiary(response);
        handleBeneficiaryView("BENEFICIARY");
      }
    });
  };

  const updateBeneficiary = async (beneficiaryForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/`,
      "Une erreur est survenue lors de la modification du bénéficiaire",
      "Le bénéficiaire a bien été modifié.",
      JSON.stringify(beneficiaryForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setBeneficiaryModuleOpen(false);
        fetchBeneficiaryList();
        fetchBeneficiaryDetail();
      }
    });
  };

  const createProject = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/`,
      "Une erreur est survenue lors de la création du projet.",
      "Le projet a bien été ajoutée.",
      JSON.stringify(projectForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setProjectModuleOpen(false);
        fetchBeneficiaryList();
        handleBeneficiaryView("PROJECT");
        fetchBeneficiaryDetail();
      }
    });
  };

  const updateProject = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/${project.id}/`,
      "Une erreur est survenue lors de la modification du projet.",
      "Le projet a bien été modifiée.",
      JSON.stringify(projectForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setProjectModuleOpen(false);
        fetchBeneficiaryList();
        fetchBeneficiaryDetail();
      }
    });
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/${project.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const deleteDocument = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const confirmDocumentDeletion = () => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${document.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteDocument(),
    });
  };

  const uploadBeneficiaryImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/image/`,
      "Une erreur est survenue lors de l'ajout d'image.",
      "L'image a bien été ajoutée au bénéficiaire.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const uploadProjectImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/${project.id}/image/`,
      "Une erreur est survenue lors de l'ajout d'image.",
      "L'image a bien été ajoutée au projet.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/image/${image.id}/`,
      "Une erreur est survenue lors de la modification de l'image.",
      "L'image a bien été modifié.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const deleteImage = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/image/${image.id}/`,
      "Une erreur est survenue lors de la suppression de l'image.",
      "L'image a bien été supprimée."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImage(undefined);
        setImageModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const handleBeneficiarySelection = (targetBeneficiary) => {
    handleSelectedBeneficiary(targetBeneficiary);
    handleBeneficiaryView("BENEFICIARY");
  };

  const handleCreateBeneficiary = () => {
    setBeneficiary(undefined);
    setBeneficiaryModuleOpen(true);
  };

  const handleUpdateBeneficiary = () => {
    setBeneficiaryModuleOpen(true);
  };

  const handleCreateBeneficiaryImage = () => {
    setImage(undefined);
    setImageModuleOpen(true);
  };

  const handleUpdateBeneficiaryImage = (image) => {
    setImage(image);
    setImageModuleOpen(true);
  };

  const handleCreateBeneficiaryProject = () => {
    setProjectModuleOpen(true);
  };

  const handleProjectSelection = () => {
    handleBeneficiaryView("PROJECT");
  };

  const handleProjectUpdate = () => {
    setProjectModuleOpen(true);
  };

  const handleProjectCreateImage = () => {
    setImage(undefined);
    setImageModuleOpen(true);
  };

  const handleProjectUpdateImage = (image) => {
    setImage(image);
    setImageModuleOpen(true);
  };

  const handleProjectCreateDocument = () => {
    setDocument(undefined);
    setDocumentModuleOpen(true);
  };

  const handleProjectUpdateDocument = (document) => {
    setDocument(document);
    setDocumentModuleOpen(true);
  };

  const handleDownloadDocument = (document) => {
    if (document) window.open(document.file, "_blank");
  };

  const handleDownloadImage = (image) => {
    if (image) window.open(image.picture, "_blank");
  };

  const breadcrumbItems = () => {
    const items = [
      {
        label: "Bénéficiaires",
        onClick: () => handleBeneficiaryView("DATATABLE"),
        isActive: navigationContext?.beneficiaryView === "DATATABLE",
      },
    ];
    beneficiary &&
      items.push({
        label: `${beneficiary.name} ${beneficiary.first_name}`,
        onClick: () => handleBeneficiaryView("BENEFICIARY"),
        isActive: navigationContext?.beneficiaryView === "BENEFICIARY",
      });
    project &&
      items.push({
        label: `${project.reference}`,
        onClick: () => handleBeneficiaryView("PROJECT"),
        isActive: navigationContext?.beneficiaryView === "PROJECT",
      });
    return items;
  };

  const mainContent = () => {
    switch (navigationContext?.beneficiaryView) {
      case "DATATABLE":
        return (
          <div className="p-4 w-full overflow-y-hidden">
            <div className="full-page-datatable">
              <BeneficiaryDatatable
                loading={datatableLoading}
                data={beneficiaryList}
                selectItem={handleBeneficiarySelection}
                handleCreateBeneficiary={isReadWrite && handleCreateBeneficiary}
              />
            </div>
          </div>
        );
      case "BENEFICIARY":
        return (
          <div className="flex flex-row h-full w-full">
            <div className="h-auto overflow-y-scroll w-full">
              <BeneficiaryDetail
                beneficiary={beneficiary}
                onProjectClick={handleProjectSelection}
                handleBeneficiaryUpdate={isReadWrite && handleUpdateBeneficiary}
                handleCreateImage={isReadWrite && handleCreateBeneficiaryImage}
                handleUpdateImage={
                  isReadWrite
                    ? handleUpdateBeneficiaryImage
                    : handleDownloadImage
                }
                handleCreateProject={
                  isReadWrite && handleCreateBeneficiaryProject
                }
              />
            </div>
          </div>
        );
      case "PROJECT":
        return (
          <div className="flex flex-row h-full w-full">
            <div className="h-auto overflow-y-scroll w-full">
              <ProjectDetail
                project={project}
                handleCreateImage={isReadWrite && handleProjectCreateImage}
                handleUpdateImage={
                  isReadWrite ? handleProjectUpdateImage : handleDownloadImage
                }
                handleCreateDocument={
                  isReadWrite && handleProjectCreateDocument
                }
                handleUpdateDocument={
                  isReadWrite
                    ? handleProjectUpdateDocument
                    : handleDownloadDocument
                }
                handleProjectUpdate={isReadWrite && handleProjectUpdate}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Header */}
      <BreadCrumb items={breadcrumbItems()} />
      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper w-full">
        {mainContent()}
      </div>

      {/* Modules */}
      <ManageBeneficiary
        loading={loading}
        productionTypeList={productionTypeList}
        beneficiary={beneficiary}
        isOpen={beneficiaryModuleOpen}
        handleClose={() => setBeneficiaryModuleOpen(false)}
        handleCreation={createBeneficiary}
        handleUpdate={updateBeneficiary}
      />

      <ManageDocument
        loading={loading}
        document={document}
        isOpen={documentModuleOpen}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={updateDocument}
        deleteDocument={confirmDocumentDeletion}
      />

      <ManageProject
        loading={loading}
        project={project}
        isOpen={projectModuleOpen}
        handleClose={() => {
          setProjectModuleOpen(false);
        }}
        handleCreation={createProject}
        handleUpdate={updateProject}
      />

      <ManageImage
        image={image}
        loading={loading}
        isOpen={imageModuleOpen}
        onClose={() => {
          setImage(null);
          setImageModuleOpen(false);
        }}
        onCreate={
          navigationContext.beneficiaryView === "BENEFICIARY"
            ? uploadBeneficiaryImage
            : uploadProjectImage
        }
        onUpdate={updateImage}
        onDelete={deleteImage}
      />
    </div>
  );
}

export default BeneficiaryPage;
