import React from "react";
import DocumentItem from "./DocumentItem";
import { useAccountContext } from "../contexts/AccountContext";

function DocumentList({
  documents = [],
  handleExistingDocument,
  requiredDocuments = [],
  handleMissingDocument,
}) {
  const downloadDocument = (d) => {
    window.open(d.file, "_blank").focus();
  };

  const filteredRequiredDocuments = requiredDocuments.filter(
    (rd) => rd.required && !documents.find((d) => d.type === rd.type)
  );
  const { isInvestor } = useAccountContext();
  if (!filteredRequiredDocuments.length && !documents.length)
    return (
      <span className="empty-section-message">
        Aucun document à afficher...
      </span>
    );
  return (
    <div className="flex flex-row flex-wrap gap-5">
      {filteredRequiredDocuments?.map((d, i) => (
        <DocumentItem
          key={i}
          document={d}
          isMissing
          onClick={() => !isInvestor && handleMissingDocument(d)}
        />
      ))}
      {documents?.map((d) => (
        <DocumentItem
          key={d.id}
          document={d}
          onClick={() =>
            isInvestor ? downloadDocument(d) : handleExistingDocument(d)
          }
        />
      ))}
    </div>
  );
}

export default DocumentList;
