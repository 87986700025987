import React, { useState, useEffect } from "react";
import { privateFetch } from "../../utils/apiHelper";
import DocumentList from "../../components/DocumentList";
import { useAccountContext } from "../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import ManageDocument from "../managers/ManageDocument";
import Section from "../../components/Display/Section";

function IntermediateDocuments() {
  const { authorizationsContext } = useAuthorizationsContext();
  const { accountContext } = useAccountContext();
  const [documentList, setDocumentList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);
  const [mandatoryDefaultType, setMandatoryDefaultType] = useState(undefined);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);

  useEffect(() => {
    fetchIntermediateDocumentList();
  }, [authorizationsContext]);

  const fetchIntermediateDocumentList = async () => {
    await privateFetch(
      "GET",
      `/intermediate/${accountContext.id}/document/`
    ).then((res) => {
      if (res) {
        setDocumentList(res);
      }
    });
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/intermediate/${accountContext.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchIntermediateDocumentList();
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/intermediate/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchIntermediateDocumentList();
    });
  };

  const handleExistingDocument = (d) => {
    setDocument(d);
    setMandatoryDefaultType(d.type);
    setDocumentModuleOpen(true);
  };

  const handleMissingDocument = (d) => {
    setDocument(undefined);
    setMandatoryDefaultType(d.type);
    setDocumentModuleOpen(true);
  };

  return (
    <Section label="Mes documents">
      <DocumentList
        handleMissingDocument={handleMissingDocument}
        handleExistingDocument={handleExistingDocument}
        requiredDocuments={CONSTANTS.INTERMEDIATE_DOCUMENTS}
        documents={documentList}
      />

      <ManageDocument
        loading={loading}
        document={document}
        options={CONSTANTS.INTERMEDIATE_DOCUMENTS}
        mandatoryType={mandatoryDefaultType || "other"}
        isOpen={documentModuleOpen}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={!document?.validated && updateDocument}
        isReadWrite
      />
    </Section>
  );
}

export default IntermediateDocuments;
