import React from "react";
import Data from "../../components/Display/Data";
import { formatDate } from "../../utils/dateHelper";

function InvestorAccount({ data }) {
  if (!data) {
    return (
      <div className="empty-section-message">Aucun compte utilisateur</div>
    );
  }
  return (
    <div className="data-section-row">
      <Data key="email" label={"Email"} value={data.email} large />
      <Data
        key="first_connexion_dt"
        label={"1ère connexion"}
        value={formatDate(data.first_connexion_dt)}
      />
      <Data
        key="creation_date"
        label={"Date de création"}
        value={formatDate(data.creation_date)}
      />
    </div>
  );
}

export default InvestorAccount;
