import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import TitlePage from "../../components/TitlePage";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import "./ManageItem.scss";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { generateYearList } from "../../utils/dateHelper";
import FormInput from "../../components/Form/FormInput";
import { SelectButton } from "primereact/selectbutton";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";

function ManageReport({
  isOpen,
  handleClose,
  handleValidation,
  investor,
  allInvestorList,
}) {
  const { getAuthorizedScopeListFromIds, getAuthorizedScopeList } =
    useAuthorizationsContext();

  const [type, setType] = useState(CONSTANTS.GENERATED_DOCUMENT_TYPES[0].value);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);

  useEffect(() => {
    setSelectedInvestor(investor);
  }, [investor, isOpen]);

  const onValidate = (toDownload) => {
    handleValidation({
      type,
      selectedYear,
      selectedInvestor,
      selectedScope,
      toDownload,
    });
  };

  const formIsValid = selectedScope;
  const footerButtons = () => {
    return (
      <div className="manage-footer">
        <Message
          className="gap-1"
          severity="info"
          text={`Un mail sera envoyé ${investor ? `à ${investor.first_name} ${investor.name}` : "à chaque investisseur"} seulement si au moins une transaction est valide ${type === "fiscal" ? "sur la période selectionnée" : ""}`}
        />
        <Button
          key={"validate"}
          label="Envoyer par le document par email"
          icon="pi pi-envelope"
          onClick={() => onValidate(false)}
          severity="success"
          disabled={!formIsValid}
        />
        {investor && (
          <Button
            key={"close"}
            label={"Télécharger le document PDF"}
            icon={"pi pi-file-pdf"}
            disabled={!formIsValid}
            onClick={() => onValidate(true)}
            severity="success"
          />
        )}
      </div>
    );
  };

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<TitlePage text={`${"Générer des documents"}`} />}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Investisseur(s) concerné(s)"}>
            <InputText
              id="investor"
              value={
                investor
                  ? `${investor.first_name} ${investor.name}`
                  : `Tous les investisseurs (${allInvestorList.length})`
              }
              disabled
            />
          </FormInput>
          <FormInput label={"Périmètre"} required isValid={selectedScope}>
            <SelectButton
              value={selectedScope}
              onChange={(e) => setSelectedScope(e.value)}
              options={
                investor
                  ? getAuthorizedScopeListFromIds(investor.linked_scopes)
                  : getAuthorizedScopeList()
              }
              optionLabel="label"
              allowEmpty={false}
            />
          </FormInput>
          <FormInput label={"Type"}>
            <div className="flex flex-row gap-x-5">
              {CONSTANTS.GENERATED_DOCUMENT_TYPES.map((dt, i) => (
                <div key={i} className="flex flex-row gap-x-2 items-center">
                  <RadioButton
                    inputId={dt.value}
                    name={dt.label}
                    value={dt.value}
                    onChange={(e) => setType(e.target.value)}
                    checked={type === dt.value}
                  />
                  <label htmlFor={dt.value}>{dt.label}</label>
                </div>
              ))}
            </div>
          </FormInput>

          {type === "fiscal" && (
            <FormInput label={"Année(s) concernée(s)"}>
              <Dropdown
                id="selectedYear"
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.value);
                }}
                options={generateYearList(2016)}
                placeholder="Toutes les années"
                showClear
              />
            </FormInput>
          )}
        </div>
        {footerButtons()}
      </div>
    </Sidebar>
  );
}

export default ManageReport;
