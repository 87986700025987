import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";
import { InputNumber } from "primereact/inputnumber";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { SelectButton } from "primereact/selectbutton";

function ManageIntermediate({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  handleDelete,
  loading,
  intermediate,
}) {
  const { getAuthorizedScopeList, getScopesListFromIds } =
    useAuthorizationsContext();

  // Mandatory
  const [name, setName] = useState("");
  const [maxFeeRate, setMaxFeeRate] = useState(3);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [representative, setRepresentative] = useState("");
  const [selectedScopeList, setSelectedScopeList] = useState([]);

  useEffect(() => {
    if (intermediate) {
      setName(intermediate.name);
      setMaxFeeRate(intermediate.max_fee_rate * 100);
      setEmail(intermediate.email);
      setPhone(intermediate.phone);
      setCity(intermediate.city);
      setZipCode(intermediate.zip_code);
      setAddress(intermediate.address);
      setRepresentative(intermediate.representative);
      setSelectedScopeList(getScopesListFromIds(intermediate.linked_scopes));
    } else {
      // Form
      setName("");
      setMaxFeeRate(3);
      setEmail("");
      setCity("");
      setZipCode("");
      setPhone("");
      setAddress("");
      setRepresentative("");
      setSelectedScopeList([]);
    }
  }, [intermediate, isOpen]);

  const onValidate = () => {
    const intermediateForm = {
      name,
      max_fee_rate: maxFeeRate / 100,
      email,
      phone,
      city,
      zip_code: zipCode,
      address,
      representative,
      linked_scopes: selectedScopeList.map((ss) => ss.id),
    };
    intermediate
      ? handleUpdate(intermediateForm)
      : handleCreation(intermediateForm);
  };

  const footerButtons = (
    <div className="manage-footer">
      {intermediate && (
        <Button
          key={"delete"}
          label={`Supprimer l'intermédiaire`}
          onClick={handleDelete}
          severity="danger"
          outlined
        />
      )}
      <Button
        key={"validate"}
        label={`Valider ${intermediate ? "la modification" : "l'ajout"}`}
        loading={loading}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${
            intermediate ? intermediate.name : "Ajouter un intermédiaire"
          }`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Nom"} required isValid={name.length > 2}>
            <InputText
              id="name"
              value={name}
              placeholder="Veuillez saisir le nom de la société"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Périmètre"}>
            <SelectButton
              multiple
              value={selectedScopeList}
              onChange={(e) => setSelectedScopeList(e.value)}
              options={getAuthorizedScopeList()}
              optionLabel="label"
            />
          </FormInput>
          <FormInput label={"Taux maximum"} required isValid={maxFeeRate}>
            <InputNumber
              value={maxFeeRate}
              placeholder="Veuillez saisir le taux maximum"
              onChange={(e) => setMaxFeeRate(e.value)}
              min={0}
              suffix="%"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Email"}>
            <InputText
              id="email"
              value={email}
              placeholder="Veuillez saisir l'email de la société"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Téléphone"}>
            <InputText
              id="phone"
              value={phone}
              placeholder="Veuillez saisir le téléphone de la société"
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Ville"}>
            <InputText
              id="city"
              value={city}
              placeholder="Veuillez saisir la ville"
              onChange={(e) => setCity(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Code postal"}>
            <InputText
              id="zip-code"
              value={zipCode}
              placeholder="Veuillez saisir le code postal"
              onChange={(e) => setZipCode(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Adresse"}>
            <InputText
              id="address"
              value={address}
              placeholder="Veuillez saisir l'adresse de la société"
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Représenté par"}>
            <InputText
              id="representative"
              value={representative}
              placeholder="Veuillez saisir le représentant de la société"
              onChange={(e) => setRepresentative(e.target.value)}
            />
          </FormInput>
        </div>

        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageIntermediate;
