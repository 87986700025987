import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import TitlePage from "../../components/TitlePage";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import "./ManageItem.scss";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";
import { Message } from "primereact/message";

function ManageUser({ isOpen, handleClose, handleCreation, loading }) {
  const [email, setEmail] = useState("");
  const [alias, setAlias] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState(undefined);

  useEffect(() => {
    setEmail("");
    setAlias("");
    setPhone("");
    setType(CONSTANTS.ACCOUNT_TYPES[0].value);
  }, [isOpen]);

  const onValidate = () => {
    const userForm = { email, alias, type, phone };
    handleCreation(userForm);
  };

  const formIsValid = alias.length > 2 && email.length > 5;

  const footerButtons = (
    <div className="manage-footer">
      {formIsValid && (
        <Message
          severity="info"
          className="text-sm"
          text={`Un mail sera envoyé à l'utilisateur avec son mot de passe`}
        />
      )}

      <Button
        key={"validate"}
        label={`Valider la création de compte`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<TitlePage text={`Ajouter un utilisateur`} />}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Email"} required isValid={email.length > 5}>
            <InputText
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Veuiller saisir l'adresse mail du compte"
            />
          </FormInput>
          <FormInput
            label={"Nom d'affichage"}
            required
            isValid={alias.length > 2}
          >
            <InputText
              id="alias"
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              placeholder="Veuiller saisir un nom d'affichage"
            />
          </FormInput>
          <FormInput label={"Type de compte"} required isValid={type}>
            <Dropdown
              id="type"
              className="form-item"
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANTS.ACCOUNT_TYPES.filter(
                (at) => at.value !== "INTERMEDIATE" && at.value !== "INVESTOR"
              )}
              optionLabel="label"
              placeholder="Sélectionner un type de compte"
            />
          </FormInput>
          <FormInput label={"Numéro de téléphone"}>
            <InputText
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Veuiller saisir le numéro de téléphone"
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageUser;
