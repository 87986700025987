import moment from "moment";

export const applyCurrencyFormat = (value) => {
  if (value === 0)
    return value.toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    });
  if (!value) return "";
  return value.toLocaleString("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
};

export const applyAreaFormat = (value) => {
  if (value === 0) return "0 m²";
  if (!value) return "";
  return `${value.toLocaleString("fr-FR", {
    minimumFractionDigits: 0,
  })} m²`;
};

export const applyHectareFormatFromArea = (value) => {
  if (value === 0) return "0 ha";
  if (!value) return "";
  const ha = Math.floor(value / 10000);
  const a = Math.floor((value - ha * 10000) / 100);
  const ca = Math.floor(value - ha * 10000 - a * 100);
  return `${ha}ha${a}a${ca}ca`;
};

export const applyDateFormat = (value) => {
  if (!value) return "";
  return moment(value).format("DD/MM/YYYY");
};

export const applyPercentage = (value) => {
  if (value === 0) return "0 %";
  if (!value) return " - ";
  return `${(value * 100).toLocaleString("fr-FR")} %`;
};
