import React, { useState, useEffect, useRef, useMemo } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";
import { AutoComplete } from "primereact/autocomplete";
import { privateFetch } from "../../utils/apiHelper";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../contexts/AccountContext";
import Tag from "../../components/Tag";
import { SelectButton } from "primereact/selectbutton";

function ManageInvestor({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  loading,
  investor,
  intermediateList,
}) {
  const { getAuthorizedScopeList, getScopesListFromIds } =
    useAuthorizationsContext();
  const { isIntermediate } = useAccountContext();

  const [existingInvestor, setExistingInvestor] = useState(undefined);

  // Mandatory
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [selectedScopeList, setSelectedScopeList] = useState([]);

  // Optionnal
  const [gender, setGender] = useState(undefined);
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [birthDate, setBirthDate] = useState(undefined);
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState(undefined);
  const [phone, setPhone] = useState("");
  const [college, setCollege] = useState(undefined);
  const [intermediate, setIntermediate] = useState(undefined);

  useEffect(() => {
    if (investor) {
      // Form
      setName(investor.name);
      setFirstName(investor.first_name);
      setGender(investor.gender);
      setCity(investor.city);
      setZipCode(investor.zip_code);
      setAddress(investor.address);
      setBirthPlace(investor.birth_place);
      setBirthDate(investor.birth_dt);
      setEmail(investor.email);
      setProfile(investor.profile);
      setPhone(investor.phone);
      setCollege(investor.college);
      setIntermediate(
        intermediateList?.find((i) => i.id === investor.intermediate?.id)
      );
      setSelectedScopeList(getScopesListFromIds(investor.linked_scopes));
    } else {
      // Form
      setName("");
      setFirstName("");
      setGender(undefined);
      setCity("");
      setZipCode("");
      setAddress("");
      setBirthPlace("");
      setBirthDate(undefined);
      setEmail("");
      setProfile(undefined);
      setPhone("");
      setCollege(undefined);
      setIntermediate(undefined);
      setSelectedScopeList([]);
    }
  }, [investor, isOpen]);

  const onValidate = () => {
    const investorForm = {
      name,
      first_name: firstName,
      gender,
      city,
      zip_code: zipCode,
      address,
      birth_place: birthPlace,
      birth_dt: birthDate ? moment(birthDate).format("YYYY-MM-DD") : undefined,
      email,
      profile,
      phone,
      college,
      intermediate: intermediate?.id,
      linked_scopes: selectedScopeList.map((ss) => ss.id),
    };
    if (investor) {
      handleUpdate(investorForm);
    } else {
      handleCreation(investorForm);
    }
  };

  const formIsValid = name.length && firstName.length;

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={"validate"}
        label={`Valider ${investor ? "la modification" : "l'ajout"}`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const intermediateTemplate = (item) => {
    if (!item) {
      return (
        <div className="row-gap">
          <span className="autocomplete-new-item">
            Sélectionner un intermédiaire
          </span>
        </div>
      );
    } else {
      return (
        <div className="autocomplete-item-container">
          <Tag key={item.id} value={item.name} />
        </div>
      );
    }
  };

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${
            investor ? `${name} ${firstName}` : "Ajouter un investisseur"
          }`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Nom"} required isValid={name.length}>
            <InputText
              id="name"
              value={name}
              placeholder="Veuillez saisir le nom de l'investisseur"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Prénom"} required isValid={firstName.length}>
            <InputText
              id="first-name"
              value={firstName}
              placeholder="Veuillez saisir le prénom de l'investisseur"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Périmètre"}>
            <SelectButton
              multiple
              value={selectedScopeList}
              onChange={(e) => setSelectedScopeList(e.value)}
              options={getAuthorizedScopeList()}
              optionLabel="label"
            />
          </FormInput>

          {!isIntermediate && (
            <FormInput label={"Intermediaire"}>
              <Dropdown
                id="intermediate"
                value={intermediate}
                onChange={(e) => setIntermediate(e.value)}
                itemTemplate={intermediateTemplate}
                valueTemplate={intermediateTemplate}
                options={intermediateList}
                optionLabel="name"
                placeholder="Veuillez sélectionner l'intermédiaire"
                showClear={true}
              />
            </FormInput>
          )}
          <FormInput label={"Civilité"}>
            <Dropdown
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.value)}
              options={CONSTANTS.GENDER_TYPES}
              optionLabel="label"
              placeholder="Veuillez sélectionner la civilité"
              showClear={true}
            />
          </FormInput>
          <FormInput label={"Ville"}>
            <InputText
              id="city"
              value={city}
              placeholder="Veuillez saisir la ville"
              onChange={(e) => setCity(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Code postal"}>
            <InputText
              id="zip-code"
              value={zipCode}
              placeholder="Veuillez saisir le code postal"
              onChange={(e) => setZipCode(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Adresse"}>
            <InputText
              id="adress"
              value={address}
              placeholder="Veuillez saisir l'adresse"
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Lieu de naissance"}>
            <InputText
              id="birthPlace"
              value={birthPlace}
              placeholder="Veuillez saisir le lieu de naissance'"
              onChange={(e) => setBirthPlace(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Date de naissance"}>
            <Calendar
              id="birth-date"
              value={birthDate}
              onChange={(e) => setBirthDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de naissance"
              maxDate={new Date()}
              locale="fr"
            />
          </FormInput>

          <FormInput label={"Téléphone"}>
            <InputText
              id="phone"
              value={phone}
              placeholder="Veuillez saisir le numéro de téléphone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Email"}>
            <InputText
              id="email"
              value={email}
              placeholder="Veuillez saisir l'adresse email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Collège"}>
            <Dropdown
              id="college"
              value={college}
              onChange={(e) => setCollege(e.value)}
              options={CONSTANTS.COLLEGE_TYPES}
              optionLabel="label"
              placeholder="Veuillez sélectionner le collège"
              showClear={true}
            />
          </FormInput>

          <FormInput label={"Profil"}>
            <Dropdown
              id="profile"
              value={profile}
              onChange={(e) => setProfile(e.value)}
              options={CONSTANTS.PROFILE_TYPES}
              optionLabel="label"
              placeholder="Veuillez sélectionner le profil"
              showClear={true}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageInvestor;
