import React, { useRef } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { applyDateFormat } from "../../utils/datatableHelper";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import Tag from "../../components/Tag";
import Search from "../../components/Datatable/Search";
import { useIntermediateContext } from "../../contexts/IntermediateContext";
import Filter from "../../components/Datatable/Filter";
import { Button } from "primereact/button";

function IntermediateDatatable({
  data,
  selectItem,
  loading,
  handleCreateIntermediate,
}) {
  const exportRef = useRef(null);

  const { getAuthorizedScopeList, getScopeFromId } = useAuthorizationsContext();
  const { intermediateContext, setIntermediateFilter } =
    useIntermediateContext();

  const handleFilter = (key, value) => {
    setIntermediateFilter(key, value);
  };

  const percentageTemplate = (rowData) => {
    return `${rowData.max_fee_rate * 100}%`;
  };

  const scopesBodyTemplate = (rowData) => {
    return (
      <div className="cell-container row-gap-xs">
        {rowData.linked_scopes.map((lsId) => (
          <Tag
            key={lsId}
            value={getScopeFromId(lsId).label}
            bgColor={"bg-" + getScopeFromId(lsId).main_color}
            rounded
          />
        ))}
      </div>
    );
  };

  const accountsBodyTemplate = (accounts) => {
    if (!accounts.length) return <Tag value={"Aucun"} rounded />;
    return (
      <Tag
        value={`${accounts.length} compte${accounts.length > 1 ? "s" : ""}`}
        bgColor={"bg-custom-green"}
        rounded
      />
    );
  };

  const filters = (
    <div className="flex flex-row gap-x-3">
      <Search
        placeholder="Filtrer par nom"
        handleSearch={(value) => handleFilter("query", value)}
        value={intermediateContext.filters.query}
      />
      <Filter
        key="scope"
        options={getAuthorizedScopeList()}
        value={intermediateContext.filters.scopeList}
        onChange={(value) => handleFilter("scopeList", value)}
        emptyLabel="Filtrer par périmètre"
      />
    </div>
  );

  const actions = (
    <div className="flex flex-row gap-x-3">
      <Button
        key={"csv"}
        size={"small"}
        onClick={() => exportRef.current.exportCSV()}
        icon={"pi pi-cloud-download"}
        label={"Exporter"}
        outlined
      />
      {handleCreateIntermediate && (
        <Button
          key={"intermediate"}
          size={"small"}
          onClick={handleCreateIntermediate}
          icon={"pi pi-plus"}
          label={"Nouveau"}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row justify-between w-full">
        {filters}
        {actions}
      </div>
      <DataTable
        ref={exportRef}
        emptyMessage={"Pas d'intermédiaire à afficher"}
        value={data}
        removableSort
        scrollable={true}
        loading={loading}
        rows={20}
        rowsPerPageOptions={[5, 20, 50, 100, 200]}
        paginator
        selectionMode="single"
        onRowSelect={(e) => selectItem(e.data)}
        exportFilename={`export_intermediaire_${moment().format("DD/MM/YYYY")}`}
        csvSeparator=";"
      >
        <Column
          sortable
          field="name"
          header="Nom"
          className="font-bold"
        ></Column>

        <Column hidden field="city" header="Ville"></Column>
        <Column hidden field="zip_code" header="Code postal"></Column>
        <Column hidden field="address" header="Adresse"></Column>
        <Column hidden field="email" header="Email"></Column>
        <Column hidden field="phone" header="Téléphone"></Column>
        <Column hidden field="representative" header="Représenté par"></Column>

        <Column
          field="linked_scopes"
          header="Périmètre(s)"
          body={scopesBodyTemplate}
        ></Column>

        <Column
          sortable
          field="investor_nb"
          header="Nb d'investisseurs"
        ></Column>
        <Column
          sortable
          field="accounts"
          header="Nb de comptes"
          body={(rowData) => accountsBodyTemplate(rowData.accounts)}
        ></Column>
        <Column
          sortable
          field="max_fee_rate"
          header="Taux max."
          body={percentageTemplate}
          dataType="numeric"
        ></Column>
        <Column
          sortable
          field="creation_date"
          header="Date de création"
          body={(rowData) => applyDateFormat(rowData.creation_date)}
          dataType="date"
        ></Column>
      </DataTable>
    </div>
  );
}

export default IntermediateDatatable;
